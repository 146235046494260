import React from 'react';
import { css, cssMap } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import UFOInteractionIgnore from '@atlaskit/react-ufo/interaction-ignore';
import type { Appearance as SpinnerAppearance } from '@atlaskit/spinner';
import Spinner from '@atlaskit/spinner';
import type { PresetSize } from '@atlaskit/spinner/types';
import { FadeIn } from '@atlaskit/motion';

import { fg } from '@confluence/feature-gating';
import { isHomePage } from '@confluence/named-routes';

export type SSRActionLoadingSpinnerProps = {
	actionType:
		| 'restrictionsButton'
		| 'watchButton'
		| 'favoriteButton'
		| 'inlineCommentButton'
		| 'pageShareAndPermsButton'
		| 'moreActionButton'
		| 'spaceStarTextButton'
		| 'dismissAdminAnnouncementBannerButton';
	spinnerId: string;
	showSpinner?: boolean;
	spinnerSize?: PresetSize;
	styleOverride?: React.CSSProperties;
	spinnerAppearance?: SpinnerAppearance;
};

const loadingSpinnerContainerStyles = css({
	font: token('font.body'),
	width: '32px',
	height: '32px',
	justifyContent: 'center',
	alignItems: 'center',
});

const spinnerStyles = cssMap({
	flex: { display: 'flex' },
	none: { display: 'none' },
});

const isWhiteBoard = (href: string) => /^\/wiki\/spaces\/[^\/]+\/whiteboard/.test(href);
const isDatabase = (href: string) => /^\/wiki\/spaces\/[^\/]+\/database/.test(href);

export const SSRActionLoadingSpinner = ({
	actionType,
	spinnerId,
	showSpinner,
	spinnerSize = 'small',
	spinnerAppearance,
	styleOverride,
}: SSRActionLoadingSpinnerProps) => {
	const showSpinnerButton: boolean = Boolean(
		(window.__SSR_EVENTS_CAPTURE__ && window.__SSR_EVENTS_CAPTURE__[`${actionType}`]) ||
			showSpinner,
	);
	const mountTime = React.useRef(Date.now() % 860);

	const urlParams = new URLSearchParams(window.location.search);
	const hydrationParam = urlParams.has('FORCE_HYDRATE', '1');

	if (
		fg('confluence_ssr_remove_action_button_clicks') ||
		hydrationParam ||
		((isDatabase(window.location.pathname) || isWhiteBoard(window.location.pathname)) &&
			fg('confluence_ssr_force_hydrate_db_wb')) ||
		(isHomePage() && fg('confluence_ssr_enable_hydration_home'))
	) {
		return null;
	}

	return (
		<UFOInteractionIgnore ignore={!showSpinnerButton}>
			<div
				data-id={spinnerId}
				data-testid={spinnerId}
				css={[loadingSpinnerContainerStyles, spinnerStyles[showSpinnerButton ? 'flex' : 'none']]}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={styleOverride}
			>
				<FadeIn>
					{(props) => (
						<Spinner
							size={spinnerSize}
							delay={-mountTime.current}
							appearance={spinnerAppearance}
							{...props}
							testId={`${spinnerId}-spinner`}
						/>
					)}
				</FadeIn>
			</div>
		</UFOInteractionIgnore>
	);
};
