import { LoadableLazy, LoadablePaint } from '@confluence/loadable';

/**
 * @deprecated Please reach out to #cc-virality for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const ChangeEditionInlineDialogLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ChangeEditionInlineDialog" */ './ChangeEditionInlineDialog'
			)
		).ChangeEditionInlineDialog,
});

/**
 * @deprecated Please reach out to #cc-virality for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const ChangeEditionInlineDialogPaintLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ChangeEditionInlineDialog" */ './ChangeEditionInlineDialog'
			)
		).ChangeEditionInlineDialog,
});
