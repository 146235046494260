import gql from 'graphql-tag';

export const GrantAccessDialogLoaderQuery = gql`
	query GrantAccessDialogLoaderQuery($contentId: ID!, $accountId: ID!) {
		singleContent(id: $contentId) {
			id
			title
		}
		user(id: $accountId) {
			id
			displayName
			photos {
				value
				isPrimary
			}
		}
	}
`;
